<template>
  <div class="d-flex flex-column">
    <alert v-if="loading" />
    <div v-else>
      <div class="card">
        <div class="p-2">
          <div class="d-flex justify-content-between align-items-center p-4">
            <h2 class="m-0">
              {{ type.displayText }}
            </h2>
            <save
              classes="btn btn-theme text-uppercase m-0"
              :disabled="!modifiedUsers.length || saving"
              :saving="saving"
              @click="sendGroups"
            >
              Save Changes
            </save>
          </div>
          <div class="form-group mb-3">
            <input
              type="search"
              class="form-control pr-5"
              @keydown.enter="searchUserText"
              v-model="searchContent"
              placeholder="Search by Email Address, First or Last Name"
            />
            <span>
              <i
                class="fa fa-search search-icon text-primary"
                @click="searchUserText"
              ></i>
              <i
                v-if="searchContent"
                class="fas fa-times cancel-icon"
                @click="(searchContent = ''), searchUserText()"
              ></i>
            </span>
          </div>
        </div>
        <div class="card-body pb-0" v-if="!users.length">
          <alert :hideLoader="true"> No {{ type.displayText }}s </alert>
        </div>
        <div class="overflow-auto" v-if="users.length">
          <table class="table table-sm table-striped mb-0 table-custom">
            <thead v-if="!mobileView">
              <tr>
                <th>Name</th>
                <th v-for="group in groups" :key="group.id">
                  <div class="d-flex align-items-center">
                    <input
                      type="radio"
                      :id="group.id"
                      name="header"
                      @click="selectAll(group.id)"
                      :checked="isGroupChecked(group.id)"
                    />
                    <label :for="group.id" class="ml-2 mb-0">
                      {{ group.name }}
                    </label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="mobileView" class="GroupList__mobile-tbody">
              <tr v-for="user in users" :key="user.id">
                <td>
                  <div class="GroupList__name">
                    <label for="GroupList__content">Name:</label>
                    <div id="GroupList__content">
                      <div>{{ user.last }}, {{ user.first }}</div>
                      <div class="small text-muted">({{ user.email }})</div>
                    </div>
                  </div>
                  <div class="GroupList__group">
                    <label>Groups:</label>
                    <div class="GroupList__group-content">
                      <label
                        v-for="group in groups"
                        :key="group.id"
                        :for="group.id + user.email"
                      >
                        <input
                          type="radio"
                          :name="user.id"
                          :id="group.id + user.email"
                          :checked="group.id === user.group_id"
                          @change="storeChanges(user, group.id)"
                        />
                        <span>{{ group.name.toUpperCase() }}</span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="user in users" :key="user.id">
                <td>
                  <div>{{ user.last }}, {{ user.first }}</div>
                  <div class="small text-muted">({{ user.email }})</div>
                </td>
                <td v-for="group in groups" :key="group.id">
                  <input
                    type="radio"
                    :id="group.id"
                    :name="user.id"
                    :checked="group.id === user.group_id"
                    @change="storeChanges(user, group.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <mugen-scroll :handler="loadMore" :should-handle="!busy && !loading">
            <alert
              v-if="busy"
              class="
                text-center
                p-2
                mb-0
                mobile-tablet-loadmore mobile-tablet-shadow-card
              "
              ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
              {{ type.displayText.toLowerCase() || "" }}s...</alert
            >
          </mugen-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MugenScroll from "vue-mugen-scroll";

export default {
  props: ["type"],
  components: { MugenScroll },
  data() {
    return {
      modifiedUsers: [],
      searchContent: "",
      latestSearch: "",
      mobileView: false,
      limit: 50,
      busy: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 1100) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  },
  watch: {
    type: function (newVal) {
      const vm = this;
      this.latestSearch = "";
      this.searchContent = "";
      this.getGroups(newVal.id).then(() => {
        const lastElement = vm.groups.length - 1;
        vm.groups[
          lastElement
        ].name = `${vm.groups[lastElement].name} ${vm.type.displayText}`;
      });
      this.getUsers({ role_id: newVal.id, limit: this.limit });
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    ...mapState({
      loading: (state) => state.permissions.groupsUsers.loading,
      saving: (state) => state.permissions.groupsUsers.saving,
      users: (state) => state.permissions.groupsUsers.pagination.data,
      current: (state) => state.permissions.groupsUsers.pagination.current,
      total: (state) => state.permissions.groupsUsers.pagination.total || 0,
      groups: (state) => state.permissions.groups,
    }),
  },
  methods: {
    ...mapActions({
      getUsers: "permissions/getGroupsUsers",
      searchUser: "permissions/search",
      getGroups: "permissions/getGroups",
      updateUsersGroups: "permissions/updateUsersGroups",
    }),
    selectAll: function (groupID) {
      this.modifiedUsers = this.users.map((user) => {
        user.groupID = groupID;
        user.group_id = groupID;
        return user.id;
      });
    },
    storeChanges: function (user, groupID) {
      user.group_id = groupID;
      user.groupID = groupID;
      if (!this.modifiedUsers.includes(user.id))
        this.modifiedUsers.push(user.id);
    },
    sendGroups: function () {
      const changedUsers = this.users.filter((user) => {
        return this.modifiedUsers.includes(user.id);
      });
      this.updateUsersGroups({ role_id: this.type.id, changedUsers }).then(
        (result) => {
          if (!result) {
            this.getUsers({ role_id: this.type.id, limit: this.limit });
          }
          this.modifiedUsers = [];
        }
      );
    },
    searchUserText: function () {
      if (this.latestSearch == this.searchContent) {
        return;
      }
      this.latestSearch = this.searchContent;
      const dataToSend = {
        role_id: this.type.id,
        limit: this.limit,
      };
      if (this.searchContent) {
        dataToSend.username = this.searchContent;
      }
      this.getUsers(dataToSend);
    },
    myEventHandler(e) {
      if (e.target.innerWidth <= 1100) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    loadMore() {
      if (this.users.length < this.total) {
        this.busy = true;
        this.getUsers({
          role_id: this.type.id,
          limit: this.limit,
          page: this.current + 1,
        }).then(() => {
          this.busy = false;
        });
      }
    },
    isGroupChecked: function (groupID) {
      const result =
        this.users.filter((user) => {
          return user.group_id === groupID;
        }).length === this.users.length;
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.GroupList__mobile-tbody {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #e7efff;
  margin-top: 4px;
  td {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    box-shadow: #062157 0px 0px 5px 0px;
  }
  .GroupList__name {
    display: flex;
    gap: 10px;
    label {
      font-size: 16px;
      font-weight: 600;
    }
    #GroupList__content {
      div:first-child {
        font-size: 16px;
      }
    }
  }
  .GroupList__group {
    display: flex;
    gap: 10px;
    > label {
      font-size: 16px;
      font-weight: 600;
    }
    .GroupList__group-content {
      width: 100%;
      font-size: 14px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      place-items: center start;
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
      }
    }
  }
}
.cancel-icon,
.search-icon {
  z-index: 99;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
.form-group {
  position: relative;
}
table {
  input[type="radio"] {
    min-width: 18px;
    min-height: 18px;
  }
  thead {
    label {
      font-size: 13px;
    }
  }
}
</style>
