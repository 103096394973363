<template>
  <div class="container">
    <div class="ml-2 description">
      <h1>Users Groups</h1>
      <p>
        Specify the access group (executive, director, general user, scheduler,
        billing, payroll, default) for each user, and based on the
        configurations in the
        <router-link :to="{ name: 'roles' }">Permissions</router-link> page the
        users will be able to interact with various fields/areas in the system
        based on the access level they were given in the permissions page.
        <br />
        <br />
        <strong>
          Use the left navigation to switch between different user roles (admin,
          manager, client, provider), and use the search field to quickly find
          users in the selected role.
        </strong>
      </p>
    </div>
    <div class="user-groups-container">
      <DrawerMenu header="Users Groups">
        <template v-slot:body="slotsProps">
          <ul class="nav-item-list">
            <li
              v-for="(section, index) in sideBarSections"
              :key="index"
              :class="
                activeSection.id === section.id ? 'nav-item active' : 'nav-item'
              "
              @click="setActive({ section, slotsProps })"
            >
              {{ section.displayText }}
            </li>
          </ul>
        </template>
      </DrawerMenu>
      <GroupsList :type="activeSection" class="user-groups-content" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GroupsList from "./GroupsList.vue";
import DrawerMenu from "../../../../components/DrawerMenu.vue";
export default {
  name: "users-roles",
  components: {
    GroupsList,
    DrawerMenu,
  },
  data() {
    return {
      activeSection: {},
    };
  },
  mounted() {
    this.activeSection = this.sideBarSections[0];
  },
  computed: {
    ...mapState({
      sideBarSections: (state) => state.permissions.sideBarSections,
    }),
  },
  methods: {
    setActive(data) {
      this.activeSection = data.section;
      data.slotsProps.toggle();
    },
  },
};
</script>

<style scoped lang="scss">
.user-groups-container {
  position: relative;
  display: flex;
  gap: 10px;
}
.user-groups-content {
  overflow: auto;
  width: 100%;
}
.nav-item-list {
  list-style: none;
  padding: 0;
}
.nav-item {
  min-height: 24px;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  padding: 12px 20px !important;
  transition: 0.1s;
}
.nav-item:hover {
  color: #ffffff50;
  transition: 0.1s;
}
.nav-item.active {
  background-color: rgba(255, 255, 255, 0.12);
}
.description {
  > p {
    background: white;
    padding: 14px 24px;
    border-radius: 0.5rem;
    a {
      color: #2c7be5;
      text-decoration: underline;
      background-color: transparent;
    }
  }
}
</style>
